import React from 'react'
import {Box, Container, css, Flex} from 'theme-ui'
import ContentContainer from '@solid-ui-components/ContentContainer'
import Reveal from '@solid-ui-components/Reveal'
import Divider from '@solid-ui-components/Divider'
import ContentText from '@solid-ui-components/ContentText'
import Icon from '@solid-ui-components/ContentIcon'
import ContentButtons from '@solid-ui-components/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'

const styles = {
    wrapper: {
        display: 'flex',
        flexFlow: 'column wrap',
        opacity: '1 !important',
    },
    box: {
        boxShadow: 'none',
        flexBasis: [`1`, `1`, `1`, `1`, `1/3`],
        textAlign: `left`,
        p: 3,
    },
    contentContainer: {
        height: '100%'
    }
}

const TripleSplit = ({content: {text, collection, buttons}}) => (
    <Container sx={{textAlign: `center`}}>
        <ContentText content={[text[0]]}/>
        <Reveal effect='fadeInGrow' css={css(styles.wrapper)}>
            {collection && (
                <>
                    <Flex sx={{flexWrap: `wrap`, m: -3}}>
                        {collection?.map(({text, icon, buttons, container, anchor}, index) => (
                            <Box sx={styles.box}
                                 key={`item-${index}`}
                            >
                                <ContentContainer content={container} variant='cards.paper'
                                                  sx={styles.contentContainer} id={anchor ? anchor : null}>
                                    {text?.[0] && (
                                        <Flex sx={{alignItems: `center`, mb: 4}}>
                                            <Icon content={icon} size='sm' mr='3' radius={'5px'}/>
                                            <ContentText content={text[0]} mb='0'/>
                                        </Flex>
                                    )}
                                    <ContentText content={text?.[1]}/>
                                    {buttons && (
                                        <>
                                            <Divider space={2}/>
                                            <ContentButtons content={buttons}/>
                                        </>
                                    )}
                                </ContentContainer>
                            </Box>
                        ))}
                    </Flex>
                </>
            )}

            <Divider space={4}/>

            <ContentText content={text.slice(1)}/>

            {buttons && (
                <>
                    <Divider space={3}/>
                    <ContentButtons content={buttons}/>
                </>
            )}
        </Reveal>
    </Container>
)

export default WithDefaultContent(TripleSplit)
